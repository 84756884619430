import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to top of the page
    if (path === '/careers') {
      window.location.reload(); // Refresh the page when navigating to the Careers page
    }
  };

  return (
    <footer id="footer" className="bg-fore text-customTextColor w-full py-4 relative overflow-hidden px-4">
      <div className="flex flex-col-2 flex-row justify-between">
        {/* Footer Links (Left) */}
        <div className="flex flex-col md:flex-row justify-start items-start space-y-2 md:space-y-0 md:space-x-4">
          <button
            onClick={() => handleNavigation('/home')}
            className="text-sm font-semibold hover:underline text-customTextColor"
          >
            Home
          </button>
          <button
            onClick={() => handleNavigation('/about')}
            className="text-sm font-semibold hover:underline text-customTextColor"
          >
            About
          </button>
          <button
            onClick={() => handleNavigation('/services')}
            className="text-sm font-semibold hover:underline text-customTextColor"
          >
            Services
          </button>
          <button
            onClick={() => handleNavigation('/eap')}
            className="text-sm font-semibold hover:underline text-customTextColor"
          >
            EAP
          </button>
          <button
            onClick={() => handleNavigation('/contact')}
            className="text-sm font-semibold hover:underline text-customTextColor"
          >
            Contact
          </button>
          <button
            onClick={() => handleNavigation('/careers')}
            className="text-sm font-semibold hover:underline text-customTextColor"
          >
            Careers
          </button>
          <button
            onClick={() => handleNavigation('/member')}
            className="text-sm font-semibold hover:underline text-customTextColor"
          >
            EAP Member Login
          </button>
        </div>
        

        {/* Footer Info and Social Media Links (Right) */}
        <div className="flex flex-col md:flex-row justify-end items-end md:space-x-4">
          {/* Social Media Links */}
          <div className="flex space-x-2">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
              aria-label="Twitter"
            >
              <FaTwitter />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
          </div>
          {/* Footer Info */}
          <div className="text-sm font-semibold text-customTextColor">
            <p>Paragon Wellness Center &copy; {new Date().getFullYear()}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
