import React, { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './Home.css';

const eapServices = [
  {
    title: 'Mental & Emotional Health Support',
    description: (
      <>
        Reach out to address mental health concerns such as depression, anxiety, substance use, and more. By partnering with a licensed clinician and sharing your past and current experiences, you can reduce symptoms and improve your quality of life. Engage in brief, solution-focused support or opt for a longer journey toward a healthier you. <strong>Start today.</strong>
      </>
    ),
  },
  {
    title: 'Substance Use Disorder Treatment',
    description: (
      <>
        Contact with your EAP Care Coordinator is strictly confidential, and help is available for securing support <strong>24 hours a day, 365 days a year.</strong> When you are living with a substance use disorder, you understand loneliness like few others. We want you to know that you do not have to be alone. We want to hear from you and help you heal from the pain driving the substance use. Through assessment and referral services, we will connect you with the appropriate level of care to meet your needs. Let us help you begin your healing journey.
      </>
    ),
  },
  {
    title: 'Work-Life Fulfillment Services',
    description: (
      <>
        Managing competing demands in the busyness of the day can be frustrating and may create distress. Life can come at you pretty fast sometimes, and there never seems to be enough hours in the day to get everything done. Let your Dedicated Care Coordinator take some of the pressure off by helping you connect with the right service provider for you.
        <ul className="list-disc pl-4 mt-12 space-y-12">
          <h1 className="font-bold text-customTextColor text-2xl">Dependent Care Referrals:</h1> We secure expert referrals for helpful resources, ensuring that your loved ones receive the care and support they need. There is comfort in knowing that your eldercare or childcare resources are just one call away.
          
          <h1 className="font-bold text-customTextColor text-2xl">Financial Consultation:</h1> We provide referrals to certified financial counselors and planners, certified public accountants, credit counselors, and other financial experts for guidance on matters such as financial planning, educational savings accounts, debt management, home buying, and more.
          
          <h1 className="font-bold text-customTextColor text-2xl">Legal Consultation:</h1> Free in-person or telephonic consultation with a licensed attorney in various areas (except employment law). Get help with civil/consumer issues, criminal matters, personal/family legal services, estate planning, and more.
          
          <h1 className="font-bold text-customTextColor text-2xl">Identity Theft Consultation:</h1> Free consultation with an identity theft recovery professional and a personalized recovery action plan.
          
          <h1 className="font-bold text-customTextColor text-2xl">Daily Convenience Referrals:</h1> Resources for everyday living such as pet care, home improvement, health and wellness, auto repair, and more.
        </ul>
      </>
    ),
  },
  {
    title: 'Health & Wellness Program',
    description: (
      <>
        Our comprehensive Health & Wellness Program is designed to help you achieve a balanced, healthier lifestyle. We offer coaching in four core areas to support your journey to wellness:
        <ul className="list-disc pl-4 mt-12 space-y-12">
          <h1 className="font-bold text-customTextColor text-2xl">Fitness Coaching:</h1> Whether you're a beginner or looking to take your fitness to the next level, our fitness coaches will develop personalized workout plans that suit your goals and fitness level. You’ll receive guidance on exercises, routines, and techniques to improve your strength, endurance, and overall physical health. Stay motivated with expert tips and support tailored to your fitness journey.
          
          <h1 className="font-bold text-customTextColor text-2xl">Nutritional Coaching:</h1> Good nutrition is the foundation of a healthy life. Our nutritional coaches will work with you to create a customized meal plan that aligns with your health goals, dietary restrictions, and lifestyle. Learn how to make healthier food choices, understand portion control, and discover the benefits of balanced eating to fuel your body and mind.
          
          <h1 className="font-bold text-customTextColor text-2xl">Stress Reduction Coaching:</h1> Stress can impact both mental and physical health. Our coaches will introduce you to proven stress-reduction techniques, including mindfulness practices, relaxation exercises, and time management strategies. With personalized support, you can learn how to better manage stress and cultivate a sense of calm in your day-to-day life.
          
          <h1 className="font-bold text-customTextColor text-2xl">Nicotine Cessation:</h1> Quitting nicotine can be a challenging process, but with the right support, it’s achievable. Our nicotine cessation coaches provide personalized strategies to help you overcome nicotine dependence. Whether you’re looking for a gradual reduction plan or immediate cessation support, we’ll guide you every step of the way with tools, tips, and ongoing encouragement.
        </ul>
        <p className="mt-8">
          Our Health & Wellness Program is designed to empower you to take control of your well-being. Each coaching program is customized to meet your individual needs, ensuring a holistic approach to improving your overall quality of life. Start your wellness journey with us today!
        </p>
      </>
    ),
  },
  
  {
    title: 'Family Support Services',
    description: (
      <>
        All family members of eligible employees residing in the household with the member, or adult children under age 26 residing outside of the home who remain on the member’s health plan, are eligible to receive all services available to the employee.
      </>
    ),
  },
  {
    title: 'Critical Incident Stress Management',
    description: (
      <>
        <h1 className="font-bold text-customTextColor text-2xl mb-7">Critical Incident Stress Management Support is Available Upon Request</h1>
        <p>Critical Incident Stress Management (CISM) is a system that helps people recover from stressful events by reducing the impact of the event, hastening recovery, and continually assessing the need for additional services. A CISM can be employed before a stressful event, educating individuals through workshops and seminars on topics such as incident awareness, crisis response strategies, and introducing coping skills for managing in the aftermath of a crisis or stressful event. A CISM can be employed during a crisis by facilitating brief, controlled discussions, a Q&A on stress levels and survival skills, which may be repeated as the situation changes. A CISM can be coordinated for after an event for those affected to share facts, vent their emotions, learn about stress, and more. This intervention is often implemented by specially trained peers and includes pre-crisis preparation, education on stress, and post-event response. It is intended to complement other services such as supportive counseling through the Employee Assistance Program (EAP), and not replace them.</p>
        <h1 className="font-bold text-customTextColor mt-16 text-2xl mb-7">Critical Incident Stress Debriefing is Available Upon Request</h1>
        <p>Critical Incident Stress Debriefing (CISD) is a voluntary, structured psycho-educational group process that supports people experiencing the psychological and physical effects of a traumatic event. This intervention is often used with first responders such as police officers, fire personnel, and emergency medical technicians, and has been proven effective with other professionals who have experienced a traumatic event. It is a storytelling process combined with practical information to normalize group members' reactions to the critical incident. The purpose of a CISD is to facilitate recovery by assisting in the resolution of symptoms related to psychological and physical effects from trauma. Coping skills can be developed in the process to reduce or even eliminate symptoms of trauma from the mind and body shortly after the traumatic event. It has been proven to aid in the development of group cohesion and to restore performance.</p>
      </>
    ),
  },
  {
    title: 'Training & Education',
    description: (
      <>
        We provide employees with monthly webinars on up-to-date work/life issues to help improve both professional and personal growth. Our sessions are available online or on-site, tailored specifically to meet your department’s needs. Our extensive training catalog covers a wide range of essential skills, including:
        <ul className="list-disc pl-4 mt-12 space-y-10">
          <h1 className="font-bold text-customTextColor text-2xl">Workplace Effectiveness:</h1> This training helps employees optimize their work habits and efficiency. Learn how to manage time effectively, set achievable goals, prioritize tasks, and increase overall productivity in a fast-paced work environment.

          <h1 className="font-bold text-customTextColor text-2xl">Management Skills:</h1> Designed for current and aspiring managers, this training covers essential skills such as team management, decision-making, delegation, and performance assessment. Equip yourself with the tools needed to lead a team effectively and inspire productivity.

          <h1 className="font-bold text-customTextColor text-2xl">Communication Skills:</h1> Clear communication is vital in any workplace. This training helps employees develop strong verbal and written communication, active listening, conflict resolution, and emotional intelligence, enabling smoother interpersonal interactions.

          <h1 className="font-bold text-customTextColor text-2xl">Leadership Skills:</h1> Learn how to become an effective leader by fostering collaboration, motivating teams, and cultivating a positive workplace culture. Topics include decision-making, conflict management, and how to inspire and empower others.

          <h1 className="font-bold text-customTextColor text-2xl">Diversity, Equity, and Inclusion (DEI):</h1> Gain insights into creating an inclusive workplace where all employees feel valued and respected. This training covers understanding biases, promoting equity, and implementing strategies to foster a diverse and inclusive environment.
        </ul>
      </>
    ),
  },
];


const EAPDetail: React.FC = () => {
  const { serviceName } = useParams<{ serviceName: string }>();
  const navigate = useNavigate();

  const service = eapServices.find(
    (service) => service.title.replace(/\s+/g, '-').toLowerCase() === serviceName
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [serviceName]);

  if (!service) {
    return <div className="text-center py-20">Service not found</div>;
  }

  return (
    <section className="py-20 min-h-screen">
      <div className="container mx-auto px-8 lg:px-1 pt-10">
        <div className="mb-4">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-customTextColor hover:text-customHoverColor font-bold py-2 px-4"
          >
            <FaArrowLeft className="mr-2" /> Back to Previous Page
          </button>
        </div>
        <div className="service-content p-10 rounded-lg text-customTextColor bg-paddingBackground shadow-lg">
          <div className="text-center">
            <h2 className="text-4xl font-bold mt-5 mb-16">{service.title}</h2>
            <div className="text-lg text-gray-600 leading-relaxed max-w-6xl mx-auto mb-6">
              {service.description}
            </div>
          </div>
        </div>
        {/* Contact Us Section */}
        <div className="text-center mt-10">
          <p className="text-lg text-gray-700 mb-4">
            Need to contact us?{' '}
            <a
              href="tel:215-792-6040" // Updated href for phone link
              className="text-blue-500 hover:text-blue-700 font-semibold"
            >
              Call us at (215)-PWC-6040
            </a>{' '}
            or fill out a{' '}
            <Link to="/contact#contact-section" className="text-blue-500 hover:text-blue-700 font-semibold">
              contact form
            </Link>{' '}
            to get in touch with us.
          </p>
        </div>
      </div>
    </section>
  );
};

export default EAPDetail;
