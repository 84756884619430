import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { FaPhoneAlt } from 'react-icons/fa';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Home.css';
import BackToTopButton from './BackToTopButton';

interface ServiceCardProps {
  serviceId: string;
  imageSrc: string;
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ serviceId, imageSrc, title, description, buttonText, onClick }) => {
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div ref={cardRef} className="service-card p-6">
      <div className="flex justify-center mb-4">
        <img src={imageSrc} alt={title} className="w-full h-auto rounded-lg" />
      </div>
      <h3 className="text-xl font-bold text-center mb-2">{title}</h3>
      <p className="text-center mb-4 text-gray-600">{description}</p>
      <div className="cta-button-wrapper">
        <button
          onClick={onClick}
          className="cta-button2 px-6 py-3 bg-customTextColor hover:bg-customHoverColor text-white font-bold rounded-full shadow-lg hover:bg-opacity-90 transition-opacity duration-300"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

interface Review {
  stars: number;
  text: string;
  date: string;
  reviewerName: string;
  reviewerImage: string;
}

const ReviewSection: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [starRating, setStarRating] = useState<number | null>(null);
  const [reviewText, setReviewText] = useState('');
  const [reviewerName, setReviewerName] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(true);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [isTypingAreaVisible, setTypingAreaVisible] = useState(false);
  const [visibleReviews, setVisibleReviews] = useState(3);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await fetch('/api/reviews', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setReviews(data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

  const handleStarClick = (stars: number) => {
    setStarRating(stars);
    setTypingAreaVisible(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleReviewSubmit = async () => {
    setErrorMessage(null);
    setIsLoading(true);

    if (!isAnonymous && reviewerName.trim() === '') {
      setErrorMessage('Please enter your name if you are not anonymous.');
      setIsLoading(false);
      return;
    }

    if (starRating !== null && reviewText.trim() !== '') {
      try {
        const response = await fetch('/api/review', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            stars: starRating,
            text: reviewText,
            reviewerName: isAnonymous ? 'Anonymous' : reviewerName,
            reviewerImage: profileImage ? profileImage : 'staff-images/Unknown_person.jpg',
          }),
        });

        const responseText = await response.text(); // Read the raw text response
        const data = JSON.parse(responseText);

        if (data.success) {
          setReviews((prevReviews) => [{ ...data, date: new Date().toISOString() }, ...prevReviews]);
          setStarRating(null);
          setReviewText('');
          setReviewerName('');
          setProfileImage(null);
          setIsAnonymous(true);
          setTypingAreaVisible(false);
        } else {
          setErrorMessage(data.message || 'Error submitting review');
        }
      } catch (error) {
        console.error('Error submitting review:', error);
        setErrorMessage('Error submitting review. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please complete the required fields.');
      setIsLoading(false);
    }
  };

  const loadMoreReviews = () => {
    setVisibleReviews(visibleReviews + 5);
  };

  const sortedReviews = [...reviews].sort((a, b) => b.stars - a.stars);

  return (
    <div className="review-section bg-paddingBackground rounded-t-lg p-8 mt-40">
      <div className="mx-auto lg:px-32">
        <div className="review-content p-8 rounded-t-md text-customTextColor bg-paddingBackground">
          <h2 className="text-2xl font-bold text-center text-customTextColor mb-4">Leave a Review</h2>
          <div className="flex justify-center items-center mb-4">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                className={`text-3xl ${star <= (starRating || 0) ? 'text-yellow-500' : 'text-backgroundOverlay'}`}
                onClick={() => handleStarClick(star)}
              >
                ★
              </button>
            ))}
          </div>

          {errorMessage && (
            <div className="mb-4 text-red-600 text-center">
              {errorMessage}
            </div>
          )}

          {isTypingAreaVisible && (
            <div className="text-center mb-4">
              <textarea
                className="w-full p-4 h-32 border border-gray-300 rounded-lg mb-4 resize-none focus:outline-none focus:ring-2 focus:ring-customTextColor transition duration-300"
                placeholder="Write your review here..."
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
              ></textarea>

              <div className="flex items-center justify-center mb-4">
                <input
                  type="checkbox"
                  checked={isAnonymous}
                  onChange={(e) => {
                    setIsAnonymous(e.target.checked);
                    if (e.target.checked) {
                      setProfileImage(null);
                    }
                  }}
                  id="anonymous"
                  className="mr-2"
                />
                <label htmlFor="anonymous" className="text-sm text-gray-700">Remain Anonymous</label>
              </div>

              {!isAnonymous && (
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Your Name"
                    className="w-64 p-2 border border-gray-300 rounded-lg mx-auto block text-center focus:outline-none focus:ring-2 focus:ring-customTextColor transition duration-300"
                    value={reviewerName}
                    onChange={(e) => setReviewerName(e.target.value)}
                  />
                </div>
              )}

              <button
                className="cta-button2 px-6 py-2 bg-customTextColor hover:bg-customHoverColor text-white font-bold rounded-full shadow-lg transition-opacity duration-300 mx-auto block"
                onClick={handleReviewSubmit}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Post Review'}
              </button>
            </div>
          )}

          <h3 className="text-xl text-customTextColor font-bold text-center my-8">Recent Reviews</h3>

          <div className="reviews mt-8 grid grid-cols-1 gap-8">
            {sortedReviews.length > 0 ? (
              sortedReviews.slice(0, visibleReviews).map((review, index) => (
                <div key={index} className="review-card p-8 rounded-lg bg-white shadow-lg text-customTextColor">
                  <div className="flex md:grid-cols-2 gap-8">
                    <div className="md:col-span-1 flex flex-col items-center md:items-start text-center md:text-left">
                      <img
                        src={review.reviewerImage}
                        alt={review.reviewerName}
                        className="w-24 h-24 mb-4 object-cover rounded-lg"
                        style={{ aspectRatio: '1/1' }}
                      />
                      <h2 className="text-xl font-bold mb-2">{review.reviewerName}</h2>
                      <div className="flex justify-center mt-1">
                        {[...Array(review.stars)].map((_, i) => (
                          <span key={i} className="text-yellow-500 text-lg">★</span>
                        ))}
                      </div>
                    </div>
                    <div className="md:col-span-1 text-center md:text-left">
                      <p className="mb-4 text-sm text-gray-600">{format(new Date(review.date), 'MMM d, yyyy')}</p>
                      <p>{review.text}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No reviews yet.</p>
            )}
          </div>

          {visibleReviews < reviews.length && (
            <div className="flex justify-center mt-8">
              <button
                className="px-6 py-2 bg-customTextColor hover:bg-customHoverColor text-white font-bold rounded-full shadow-lg transition-opacity duration-300"
                onClick={loadMoreReviews}
              >
                Load More Reviews
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  const navigate = useNavigate();

  const handleServiceClick = (serviceId: string) => {
    navigate(`/services#${serviceId}`);
  };

  const handleServicesClick = () => {
    navigate('/services');
    window.scrollTo(0, 0);
  };

  const handleEapClick = () => {
    navigate('/eap');
    window.scrollTo(0, 0);
  };

  // Slick slider settings for horizontal scrolling of service cards
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
<section id="home-section" className="text-center min-h-screen overflow-y-auto">
  <div className="relative w-full justify-center items-center" style={{ height: '590px' }}>
    <div className="image-wrapper relative flex flex-col justify-center items-center">
      <div className="image-gradient-overlay"></div>
      <div className="image-green-gradient-overlay"></div>
      <img src="/locations/philadelphia.jpg" alt="Background Banner" className="image" />

      <div className="overlay-text text-beige">
        <h1 className="reveal-text mt-44 text-4xl md:text-5xl lg:text-5xl font-bold custom-text-shadow">
          Welcome to Paragon Wellness Center
        </h1>
        <h2 className="text-lg md:text-xl lg:text-3xl reveal-text italic mt-5 font-semibold custom-text-shadow">
          Committed to providing exceptional care for your overall well-being
        </h2>

        <div className="button-container mt-8">
          <div className="button-wrapper grid grid-cols-2 gap-x-12 gap-y-4 md:flex md:items-center md:justify-center">
            
            {/* First Button */}
            <div className="flex flex-col items-center md:flex-row md:items-center">
              <button
                onClick={handleServicesClick}
                className="cta-button1 px-2 py-2 xs:px-2 xs:py-2 md:px-6 md:py-3 lg:px-5 lg:py-3"
              >
                <span>Services</span>
              </button>
            </div>

            <div className="flex flex-col items-center md:flex-row md:items-center">
              <button
                onClick={handleEapClick}
                className="cta-button1 px-4 py-2 md:px-6 md:py-3 lg:px-5 lg:py-3"
              >
                <span>EAP</span>
              </button>
            </div>

            <div className="flex flex-col items-center md:flex-row md:items-center">
              <button
                onClick={() => navigate('/contact#contact-section')}
                className="cta-button1 px-4 py-2 md:px-6 md:py-3 lg:px-5 lg:py-3"
              >
                <span>Contact</span>
              </button>
            </div>

            <div className="flex flex-col items-center md:flex-row md:items-center">
              <button
                onClick={() => navigate('/contact#location-section')}
                className="cta-button1 px-4 py-2 md:px-6 md:py-3 lg:px-5 lg:py-3"
              >
                <span>Location</span>
              </button>
            </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services-section bg-paddingBackground rounded-lg p-8 pb-12 mt-7">
        <div className="home-content p-8 rounded-lg text-customTextColor bg-paddingBackground">
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-2xl font-bold mb-2">Our Services</h2>

            {/* Slider for service cards */}
            <Slider {...sliderSettings} className="w-full mt-8">
              <ServiceCard
                serviceId="clinical"
                imageSrc="/home-images/clinical.jpg"
                title="Clinical Services"
                description="Discover our clinical services tailored to enhance your well-being."
                buttonText="Learn More"
                onClick={() => handleServiceClick('clinical')}
              />
              <ServiceCard
                serviceId="eap"
                imageSrc="/service-images/work-life-balance.webp"
                title="Employee Assistance Program"
                description="Learn about our EAP services to help you navigate life's challenges."
                buttonText="Learn More"
                onClick={() => handleEapClick()}
              />
              <ServiceCard
                serviceId="medical"
                imageSrc="/home-images/Medical.jpg"
                title="Medical Services"
                description="Access top-notch medical care tailored to your specific needs."
                buttonText="Learn More"
                onClick={() => handleServiceClick('medical')}
              />
              <ServiceCard
                serviceId="case-management"
                imageSrc="/home-images/management.jpg"
                title=" Clinical Case Management"
                description="Learn about our Case Management Services."
                buttonText="Learn More"
                onClick={() => handleServiceClick('case-management')}
              />
            </Slider>
          </div>
        </div>
        <div className="cta-button-wrapper flex justify-center items-center mt-7">
      <a
        href="tel:215-792-6040"
        className="cta-button2 px-6 py-3 bg-customTextColor hover:bg-customHoverColor text-white font-bold rounded-full shadow-lg hover:bg-opacity-90 transition-opacity duration-300 flex items-center"
      >
        <FaPhoneAlt className="text-white h-5 w-5 mr-2" /> {/* Adjusted size here */}
        Call Us: (215)-PWC-6040
      </a>
    </div>
      </div>
      <BackToTopButton />
      <ReviewSection />
    </section>
  );
};

export default Home;