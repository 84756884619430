import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Banner from './Banner';

const Navbar: React.FC = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
  const [isMobileNavVisibleOnScroll, setIsMobileNavVisibleOnScroll] = useState(true);
  const [isMainNavVisible, setIsMainNavVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null);
  const [lastScrollY, setLastScrollY] = useState(0);
  const bannerRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleScroll() {
      const currentScrollY = window.scrollY;
      if (bannerRef.current) {
        const bannerBottom = bannerRef.current.offsetHeight;
        setIsSticky(currentScrollY > bannerBottom);
      }

      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setScrollDirection('down');
        setIsMobileNavVisibleOnScroll(false);
        setIsMenuOpen(false);
      } else if (currentScrollY < lastScrollY) {
        setScrollDirection('up');
        setIsMobileNavVisibleOnScroll(true);
      }

      setLastScrollY(currentScrollY);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768;
      setIsMobileNavVisible(isMobile);
      setIsMainNavVisible(!isMobile);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    }

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMenuOpen(false); // Close menu when navigating
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  return (
    <>
      {/* Banner */}
      <Banner isVisible={!isMobileNavVisible && !isMenuOpen} bannerRef={bannerRef} />

      {/* Desktop Nav */}
      <nav
        ref={navbarRef}
        className={`bg-white p-4 w-full z-10 transition-transform duration-300 ${
          isSticky ? 'sticky top-0' : 'relative'
        } ${isMainNavVisible ? 'block' : 'hidden'}`}
        style={{ boxShadow: '1px 2px 12px rgba(0, 0, 0, 0.8)' }}
      >
        <div className="w-full relative overflow-hidden">
          <div className="flex justify-between items-center h-11 mx-[-2rem] px-14">
            <div className="flex">
              <button onClick={() => handleNavigation('/home')} className="text-lg flex font-bold items-center">
                <img src="/logos/logoIcon.png" alt="logo" className="h-11 w-full inline-block mr-2 pl-3" />
              </button>
            </div>
            <div className="hidden md:flex space-x-8">
              <button onClick={() => handleNavigation('/home')} className="text-customTextColor text-lg font-semibold hover:text-customHoverColor">
                Home
              </button>
              <button onClick={() => handleNavigation('/about')} className="text-customTextColor text-lg font-semibold hover:text-customHoverColor">
                About
              </button>
              <button onClick={() => handleNavigation('/services')} className="text-customTextColor text-lg font-semibold hover:text-customHoverColor">
                Services
              </button>
              <button onClick={() => handleNavigation('/eap')} className="text-customTextColor text-lg font-semibold hover:text-customHoverColor">
                EAP
              </button>
              <button onClick={() => handleNavigation('/blog')} className="text-customTextColor text-lg font-semibold hover:text-customHoverColor">
                Blog
              </button>
              <button onClick={() => handleNavigation('/contact')} className="text-customTextColor text-lg font-semibold hover:text-customHoverColor">
                Contact
              </button>
              <button onClick={() => handleNavigation('/member')} className="text-customTextColor text-lg font-semibold hover:text-customHoverColor">
                 EAP Member Login
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Nav */}
      <nav
        className={`md:hidden fixed top-0 w-full bg-white z-20 transition-transform duration-300 ${
          isMobileNavVisible ? (isMobileNavVisibleOnScroll ? 'transform translate-y-0' : 'transform -translate-y-full') : 'hidden'
        }`}
        style={{ boxShadow: '1px 2px 12px rgba(0, 0, 0, 0.8)' }}
      >
        <div className="flex justify-between items-center p-4">
          <button
            onClick={toggleMenu}
            className="text-customTextColor text-xl font-bold hover:text-customHoverColor"
          >
            &#9776;
          </button>
          
          <Link to="/home" className="text-gray-700 text-lg font-bold mx-auto relative" onClick={() => handleNavigation('/home')}>
            <img src="/logos/fullLogo.png" alt="logo" className="mobile-logo w-auto inline-block" />
            <div className="absolute left-48 transform -translate-x-1/2 top-14 text-customTextColor italic text-xs underline">
              Opening Soon
            </div>
          </Link>

          <div className=""></div>
        </div>

        {/* Mobile Nav Menu */}
        {isMenuOpen && (
          <div ref={menuRef} className="absolute left-0 w-52 bg-white text-customTextColor p-4 z-10" style={{ boxShadow: '1px 2px 12px rgba(0, 0, 0, 0.8)' }}>
            <button onClick={() => handleNavigation('/home')} className="block text-lg py-2 font-semibold hover:text-customHoverColor">
              Home
            </button>
            <button onClick={() => handleNavigation('/about')} className="block text-lg py-2 font-semibold hover:text-customHoverColor">
              About
            </button>
            <button onClick={() => handleNavigation('/services')} className="block text-lg py-2 font-semibold hover:text-customHoverColor">
              Services
            </button>
            <button onClick={() => handleNavigation('/eap')} className="block text-lg py-2 font-semibold hover:text-customHoverColor">
              EAP
            </button>
            <button onClick={() => handleNavigation('/blog')} className="block text-lg py-2 font-semibold hover:text-customHoverColor">
              Blog
            </button>
            <button onClick={() => handleNavigation('/contact')} className="block text-lg py-2 font-semibold hover:text-customHoverColor">
              Contact
            </button>
            <button onClick={() => handleNavigation('/member')} className="block text-lg py-2 font-semibold hover:text-customHoverColor">
              EAP Member Login
            </button>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
