import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './About.css'; // Assuming the same CSS file can be used for the staff page
import BackToTopButton from './BackToTopButton';

interface StaffMember {
  name: string;
  position: string;
  position2?: string;
  summary?: string;
  imageUrl: string;
  link?: string;
  category: string | string[];
}

const sortByLastName = (staffMembers: StaffMember[]): StaffMember[] => {
  return staffMembers.sort((a, b) => {
    const lastNameA = a.name.split(' ').pop()?.toLowerCase() || '';
    const lastNameB = b.name.split(' ').pop()?.toLowerCase() || '';
    return lastNameA.localeCompare(lastNameB);
  });
};

const Staff: React.FC = () => {
  const staff: StaffMember[] = [
    { name: 'Kimberly Pakech', position: 'CEO', position2: 'LCSW, LCADC, LICSW, SAP Qualified', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', link: 'https://www.tebra.com/care/provider/kimberly-pakech-mss-mlsp-lcsw-1891068920', category: ['executive', 'provider'] },
    { name: 'Ashley Meehan', position: 'CFO', position2: '(Chief Financial Officer)', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', category: 'executive' },
    { name: 'Matthew Darabasz', position: 'CTO', position2: '(Chief Technology Officer)', summary: '', imageUrl: '/staff-images/matt.jpg', category: 'executive' },
    { name: 'James B. Watson', position: 'DO', position2: '(Medical Director)', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', category: 'mdirector' },
    { name: 'Claire Keller', position: 'Office Manager', position2: '', summary: "Claire has been in long-term recovery from substance use for 35 years...", imageUrl: '/staff-images/claire_keller.jpg', category: 'managers' },
    { name: 'Dawn Pakech', position: 'Clinical Supervisor', position2: '', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', category: 'managers' },
    { name: 'Elizabeth Higgins', position: 'Certified Recovery Specialist (CRS)', position2: 'Peer Support Specialist', summary: "Elizabeth 'Liz' Higgins is a Certified Recovery Specialist (CRS)...", imageUrl: '/staff-images/elizabeth-higgins.jpg', category: 'supportspecialist' },
    { name: 'Tina M. Wright', position: 'Therapist', position2: 'MA, CADC Certified', summary: "Tina has been practicing in the mental and behavioral health space...", imageUrl: '/staff-images/tina-wright.jpg', category: 'provider' },
    { name: 'Mark Ryan', position: 'Therapist', position2: '', summary: '', imageUrl: '/staff-images/mark-ryan.jpg', category: 'provider' },
    { name: 'Roxanne Logan', position: 'Therapist', position2: 'JD, MSS, LCSW Certified', summary: "Roxanne Logan is a therapist with law and social work degrees...", imageUrl: '/staff-images/roxanne.jpg', category: 'provider' },
    { name: 'Burnadette Bordoni', position: 'Counseling Intern', position2: '', summary: "At Paragon Wellness, we believe in social and emotional safety for all...", imageUrl: '/staff-images/burnadette.jpg', category: 'provider' },
    { name: 'Allison Lagera', position: 'Administrative Assistant', position2: '', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', category: 'supportspecialist' },
  ];

  const executives = sortByLastName(staff.filter(member => (Array.isArray(member.category) ? member.category.includes('executive') : member.category === 'executive')));
  const mdirector = sortByLastName(staff.filter(member => (Array.isArray(member.category) ? member.category.includes('mdirector') : member.category === 'mdirector')));
  const managers = sortByLastName(staff.filter(member => (Array.isArray(member.category) ? member.category.includes('managers') : member.category === 'managers')));
  const providers = sortByLastName(staff.filter(member => (Array.isArray(member.category) ? member.category.includes('provider') : member.category === 'provider')));
  const supportSpecialists = sortByLastName(staff.filter(member => (Array.isArray(member.category) ? member.category.includes('supportspecialist') : member.category === 'supportspecialist')));

  const settings = (memberCount: number) => ({
    dots: true,
    infinite: false, 
    speed: 500,
    slidesToShow: memberCount >= 3 ? 3 : memberCount, 
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: memberCount >= 2 ? 2 : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    const cards = document.querySelectorAll('.staff-card');
    cards.forEach(card => {
      if (observerRef.current) observerRef.current.observe(card);
    });

    return () => {
      if (observerRef.current) {
        cards.forEach(card => {
          if (observerRef.current) observerRef.current.unobserve(card);
        });
      }
    };
  }, []);

  const renderStaffCards = (members: StaffMember[], isClickable: boolean) => (
    <Slider {...settings(members.length)}>
      {members.map((member, index) => (
        <div key={index} className="p-6">
          {isClickable ? (
            // For clickable cards (e.g., non-executive and non-medical director)
            <Link to={`/staff/${member.name.replace(/\s+/g, '-').toLowerCase()}`}>
              <div className="staff-card p-6 rounded-lg flex flex-col items-center text-center shadow-lg">
                <img src={member.imageUrl} alt={member.name} className="w-32 h-32 rounded-full mb-4 object-cover" />
                <h3 className="text-xl font-bold">{member.name}</h3>
                <p className="text-md text-gray-600">{member.position}</p>
                <p className="text-md text-gray-600">{member.position2}</p>
              </div>
            </Link>
          ) : (
            // For non-clickable cards (e.g., executives and medical director)
            <div className="staff-card p-6 rounded-lg flex flex-col items-center text-center shadow-lg">
              <img src={member.imageUrl} alt={member.name} className="w-32 h-32 rounded-full mb-4 object-cover" />
              <h3 className="text-xl font-bold">{member.name}</h3>
              <p className="text-md text-gray-600">{member.position}</p>
              <p className="text-md text-gray-600">{member.position2}</p>
            </div>
          )}

          {member.link && (
            <a
              href={member.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 inline-block bg-customTextColor text-white font-semibold py-2 px-4 rounded-full hover:bg-customHoverColor"
            >
              Schedule Appointment
            </a>
          )}
        </div>
      ))}
    </Slider>
  );

  return (
    <section id="staff-section" className="py-20 text-center min-h-screen overflow-y-auto bg-beige text-customTextColor">
      <div className="container mx-auto px-8 lg:px-1 pt-10">
        {/* About Section */}
       <section id="about">
        <div className="about-content p-8 rounded-lg bg-white text-customTextColor shadow-lg">
        <h2 className="text-4xl font-bold mb-10 mt-4 text-customTextColor">About Us</h2>

          <div className="text-2xl mb-4 mt-10 text-customTextColor">
            <p className="italic mb-6 md:px-16">
            Paragon Wellness Center, LLC is a dynamic outpatient treatment program found in the heart of Port Richmond at 3396 Miller Street, Philadelphia, PA 19134. 
            Our extensive service lines offer those suffering in silence a wide array of treatment options for healing from the loneliness and despair of substance use disorder and 
            co-occurring mental health conditions. Our clinical team will provide compassionate mental, behavioral, and emotional health and wellbeing care including trauma specific 
            treatment for those carrying tremendous stress and heartache from a one-time traumatic event or having the grave misfortune of prolonged exposure to adverse conditions.
            </p>
            <p className="italic mb-6 md:px-16">
            In addition to direct care, Paragon Wellness Center is well-placed to serve as an Employee Assistance Program (EAP) Professional Provider Organization. 
            The goal of our clinical team and supportive professional providers is to cast our net wide to reach as many lives as we possibly can in effort to effect 
            a true connection. We invite each individual to leap with courage, to search deep within, and to consider the opportunity to chart a new path however narrow 
            it may be. We are intent on serving Employers who understand the weight today’s demands have on their employees as they make a vigorous effort to balance work, 
            play, and love every day. 
            </p>
            <p className="italic mb-24 md:px-16">
            Just as a burden shared is a burden lessened, and at times even lifted, our “soul” aim is to stand ready to walk along the road to wellness with anyone willing
            enough to travel with us! 
            </p>
          </div>

          <div className="mb-10 mx-auto h-1 w-52 bg-oliveGreen rounded-md"></div>

          <h3 className="text-3xl font-bold mb-4 text-customTextColor">Mission</h3>
          <p className="text-xl italic mb-14 md:px-14">
            Welcoming everyone who crosses the threshold with a warm smile and an
            outstretched hand. Listening with empathy to ensure the conveyance of love
            and the opportunity for healing. Offering intentional care in service to the larger
            vision.
          </p>
          
          <h3 className="text-3xl font-bold mb-4 text-customTextColor pt-8">Vision</h3>
          <p className="text-xl italic mb-10 md:px-14">
            To be experienced as a safe place for those who are hurting, where they may find the joy of
            living through a journey that leads to health and well-being. Impacting the
            community and its members in a meaningful way, ending the silent suffering that accompanies
            substance use disorders and mental health conditions. Eradicating shame and fear, one person, one family at a time, with a team of
            professionals committed to the compassionate art of understanding.
          </p>
        </div>
      </section>

        {/* Staff Section */}
        {/* <section id="staff" className="mb-16 pt-24">
          <h2 className="text-4xl font-bold mb-10 text-customTextColor">Meet The Staff</h2>
          <div className="staff-content p-8 rounded-lg bg-white text-customTextColor shadow-lg">
            <h3 className="text-xl font-bold mb-6 text-customTextColor">Executives</h3>
            {renderStaffCards(executives, false)}
  
            <h3 className="text-xl font-bold mt-12 mb-6 text-customTextColor">Medical Director</h3>
            {renderStaffCards(mdirector, false)}
  
            <h3 className="text-xl font-bold mt-12 mb-6 text-customTextColor">Managers</h3>
            {renderStaffCards(managers, true)}
  
            <h3 className="text-xl font-bold mt-12 mb-6 text-customTextColor">Providers</h3>
            {renderStaffCards(providers, true)}
  
            <h3 className="text-xl font-bold mt-12 mb-6 text-customTextColor">Support Specialists</h3>
            {renderStaffCards(supportSpecialists, true)}
          </div>
        </section> */}
      </div>
      <BackToTopButton />
    </section>
  );
};

export default Staff;
