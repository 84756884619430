import React, { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './ServiceDetail.css';

const services = [
  {
    title: 'Individual Therapy',
    description: `
      <p>Talk Therapy with a trained mental health professional offers a safe environment to alleviate symptoms and resolve conflicts related to matters such as Depression, Anxiety, Parenting Problems, Work Related Conflict, Career Counseling, Relationship Issues, Anger Management, and many other personal life situations that require supportive intervention.</p>

      <p>Our goal is to collaborate with clients in individual sessions providing effective treatment for a variety of conditions and concerns promoting self-awareness and emotional well-being. We offer brief solution-focused therapy or clients can opt for a longer-term model depending upon the nature of the precipitating event.</p>

      <p>All individuals enrolled in our outpatient programming will receive individual therapy as an added support to their clinical care. This integrated approach ensures that therapy complements their overall treatment plan, enhancing both progress and well-being.</p>
    `,
  },
  {
    title: 'Group Therapy',
    description: 'Group Psychotherapy with a trusted clinician and similarly situated individuals offers an opportunity for group members to connect with one another around life matters that require constructive conversation, supportive collaboration, and alternative life and coping skills building. In a group setting, the emotional maturation process is easier to develop but calls for courage as the individual in the group takes risks in becoming increasingly more vulnerable in the setting. Investment is essential but the reward is incalculable.',
  },
  {
    title: 'Psychodrama',
    description: 'Psychodrama is a therapeutic approach that uses guided drama and role-playing to help individuals explore and address emotional issues, conflicts, and traumas. This method allows participants to reenact past experiences and gain new insights into their feelings and behaviors, fostering personal growth and healing through creative expression.',
  },
  {
    title: 'Trauma Treatment',
    description: 'Trauma Therapy provides the individual with the opportunity and support necessary to develop coping mechanisms for incremental healing. There are a wide range of options available for trauma work, including Talk Therapy, Small Group Therapy, EMDR, Dynamical Neurofeedback, and our Peer Support Program, Triumph Over Tragedy. These modalities, when intricately woven together, provide a firm foundation for lasting change and healing for those who have long suffered from trauma.',
  },
  {
    title: 'Clinical Treatment',
    description: `Clinical treatment is intentional and planned. It is a professionally directed set of deliberate interventions performed by a licensed clinician or physician for the purpose of reducing or eliminating symptoms that arise from an underlying condition such as an event or series of events resulting in trauma or Post Traumatic Stress Disorder (PTSD), or Substance Use Disorder (SUD) concerns, and other diagnosed mental, behavioral, or emotional health conditions occurring as manifestations of mental and emotional pain. These deliberate interventions are implemented to effect change and promote a healthier lifestyle. Methods may include but are not limited to Individual Psychodynamic Psychotherapy, other Supportive Counseling approaches such as Motivational Interviewing, methodologies such as DBT or CBT, Psychodrama, Psychoeducational Group Therapy, or Process Group Therapy. There are a multitude of clinical interventions to support individuals, couples, and families to recover and to become restored to wholeness.`,
  },
  {
    title: 'Clinical Evaluation & Diagnosis',
    description: `
      <p>The purpose of a clinical evaluation is to identify issues and concerns precisely to ensure the highest quality of care. Being certain to provide safe, effective, and person-centered care is the sole aim of our body of work. The clinical evaluation is comprised of a question-and-answer exchange between the evaluator and the prospective client, and also includes direct observation as well as clinical reasoning and analytic skills. Of importance will be historical data regarding medical and mental health, past and current behavioral health information and verbal report on functioning, gaining knowledge of an individual’s personal, family, and social history, and securing as much detail about a person’s values, preferences, goals, and relevant information that is meaningful to the individual interviewee.</p>

      <p>The clinical evaluation coupled with clinical reasoning and applied analytics results in a diagnosis which informs the treatment plan, lends itself to the choice in methodology which drives the intervention. Equally as important is the fluidity of the process. As the individual changes their needs change, and the approach often changes but it all begins with a comprehensive clinical evaluation and diagnosis.</p>
    `,
  },
  {
    title: 'Medical Evaluation & Recommendation',
    description: 'Whether contacting our office directly or scheduling through our website link, clients are able to be evaluated in-person or by telehealth if appropriate based on need at the time of scheduling. Our Medical Director, Dr. James B. Watson, DO or a medical professional provider on our team will conduct a full medical history, consider the current report of health concerns, discuss evidence-best treatment options with the client, and make medical recommendations based on what is in the best interest of the client, as well as ensuring the highest standard of care.',
  },
  {
    title: 'Medical Care',
    description: `
      <p>Medical care will be provided by physicians licensed to practice in Pennsylvania, or Physician Assistants and Registered Nurses licensed by the State and trained by our Medical Director to ensure the standard of care exceeds regulatory guidelines. Clients receiving medical care with us will be active decision makers throughout the treatment process. In the spirit of collaboration and based on direct observation by our medical professional(s), as well as verbal reports made by the client, options will be presented and a Medical Treatment Plan will be developed.</p>

      <p>We offer Ambulatory Detoxification Services for those afflicted with Alcohol Use Disorder. Candidates who are determined appropriate for an individualized detoxification treatment plan will be required to meet strict eligibility criteria ensuring that client safety is the guidepost.</p>

      <p>This unique individualized treatment opportunity will be determined appropriately by the Medical Director following a comprehensive medical history and thorough evaluation of the potential candidate including any personal, medical, and social risk factors. Treatment services will be monitored 24 hours until the client is stable. Active participation in behavioral healthcare is strongly encouraged as a supplemental service to ensure added support is available.</p>

      <p>Opioid Use Disorder detoxification is also offered to our client base yet transition to stability and sobriety proceeds along a weightier pathway. In partnership with our Medical Director or an alternate licensed medical professional on our team the client will discuss options for achieving freedom from active addiction following the safest and most effective trajectory toward success. This too is a 24-hour monitored care line available to candidates meeting all eligibility criteria for participation in this intentionally designed treatment course. Engagement in behavioral healthcare services is often outlined in the medical treatment plan.</p>
    `,
  },
  {
    title: 'Medical Case Management', 
    description: 'Clients receiving medically managed care through our Ambulatory Detoxification Program are actively involved in their medical treatment planning process and responsible to provide all relevant medical health information, authorize the release of medical treatment information to external treatment providers having a stake in the plan of care being administered. Primary care physicians, psychiatrists, mental health treatment providers, and any specialists that are providing present care or scheduled to provide care that would overlap the detoxification process  will be apprised with the expressed intent to avert risks due to contraindication. Our medical professionals will collaborate with external treatment partners to ensure the health and safety of the clients under our care.',
  },
  {
    title: 'Referral Services',
    description: 'Our goal is to offer help and hope to every individual that crosses our threshold. With this aim in heart and mind we are poised to provide Ambulatory Detoxification as a solution-focused treatment to persons who meet specific eligibility criteria for beginning the recovery process from Alcohol Use Disorder. Under the guidance of our Medical Director and supportive medical professional provider we endeavor to administer a safe and effective course of treatment for a unique cohort of candidates appropriate for this level of care on an outpatient basis. Further, we are able to extend a similarly unique level of care to those living with Opioid Use Disorder. A longer yet effective approach to recovery from opioid dependence can be explored with our Medical Director or a member of the medical professional provider team to determine goodness of fit. Should the determination be made that Ambulatory Detoxification is not indicated our Medical Director or an alternate medical professional provider on our treatment team will make recommendations for the appropriate level of care and referral services will be coordinated through our clinical or case management team only after case consultation is completed.',
  },
  {
    title: 'Supportive Services',
    description: 'Our team offers case management services as a fundamental element of the outpatient treatment program. Case Management services add a layer of support to our clients optimizing their healthcare experience. Each client will develop a Wellness Recovery Action Plan or WRAP Plan with a member of our clinical team outlining psychosocial goals, highlighting objectives, and moving toward potential outcomes. A journey to wellness would be considered incomplete without this navigational tool for assistance along the path.',
  },
  {
    title: 'Resource Coordination',
    description: 'We endeavor to keep our finger on the pulse of resources available to our clients during a critical time in their lives. Active engagement in outpatient care can be strenuous as one traverses uncharted emotional terrain for the first time or is struggling to continue the trek due to prior attempts at reaching higher ground and not having achieved their desired outcome. Our case management team members honor the efforts made by everyone. They do this by holding space with them as they disclose the many areas of their lives that have been negatively impacted by their personal struggles. A friendly outstretched hand searching the wider community for much needed resources can result in a need fulfilled and a burden lifted. We commit to exploring all available avenues to support our clients at every turn.',
  },
  {
    title: 'Professional Collaboration',
    description: 'Case Management requires a commitment to excellence in effort often serving as the bridge between the client and the larger professional community. Should a client require additional support our case managers will work in collaboration with our community treatment partners to offer viable options for parallel care or if appropriate, connecting to the service post successful completion and discharge.',
  },
  {
    title: 'After Care Planning',
    description: 'After Care Planning is significant and begins upon admission for clients under our care. Developing a treatment plan that includes goals and objectives that are specific, measurable, and attainable during care is essential to the change process and incremental success often builds momentum and fosters hope. Hope lends itself to the development of optimism and strengthens one’s resolve thereby deepening the investment in personal progress. Identifying important aspirations for beyond the now allows for continued improvement and cultivates a growth mindset. An aftercare plan provides a road map for continuing a personal journey of greater health and well-being, and an increase in quality-of-life experience along the way.',
  },
  {
    title: 'Follow up Case Management',
    description: 'Treatment beginnings are often challenging. People are hurting and at times fear filled. Time, quality care and emotional support permit stabilization and eventual relaxation. As the change process unfolds and hope rises, momentum builds. Aftercare planning being integral to the continued growth plan, we find that Follow Up Case Management is vital to the overall vision that drives our mission. Sharing with each client that they are not alone and connection in the key to healing is the start but demonstrating that principle through intermittent contact builds trust. We place outreach calls to our former clients regardless of their status at the time of discharge at 30 days, 60 days, 90 days, and 180 days post treatment to continue to serve as a positive relationship to our client base, to re-engage people in the treatment process should they have experienced a setback, and to encourage active participation in our alumni or to seek an altruistic endeavor of their choice.',
  },
  {
    title: '24-Hour Support',
    description: 'We offer 24-hour support for various needs, providing immediate assistance and guidance to individuals who require help outside of regular business hours. This service ensures that individuals have access to support and resources whenever they need them.',
  },
  {
    title: 'Confidential Brief Solution Focused Counseling',
    description: 'Confidential Brief Solution Focused Counseling provides short-term, targeted therapy aimed at addressing specific issues and achieving practical solutions. This approach focuses on identifying and building on existing strengths and resources to resolve immediate concerns in a confidential setting.',
  },
  {
    title: 'Mental & Emotional Health Support',
    description: 'Mental Health Support includes a range of services designed to address and manage mental health conditions. This encompasses therapy, counseling, support groups, and other resources aimed at improving mental well-being and providing assistance in coping with various challenges.',
  },
  {
    title: 'Substance Use Disorder Treatment',
    description: 'Substance Use Disorder Treatment offers specialized care and interventions for individuals struggling with addiction or substance abuse. This includes detoxification, counseling, therapy, and support services tailored to help individuals achieve and maintain sobriety.',
  },
  {
    title: 'Work-Life Balance Resources',
    description: 'Work-Life Balance Resources provide support and strategies to help individuals manage and balance their professional and personal lives. This includes counseling, workshops, and resources aimed at reducing stress and enhancing overall well-being.',
  },
  {
    title: 'Health & Wellness Program',
    description: 'Health & Wellness Program offers a comprehensive approach to improving overall health and well-being. This includes physical fitness, nutrition guidance, stress management, and other holistic practices designed to promote a healthy lifestyle.',
  },
  {
    title: 'Family Support Services',
    description: 'Family Support Services provide assistance and resources to families dealing with mental health issues or other challenges. This includes counseling, education, and support programs aimed at strengthening family relationships and improving overall functioning.',
  },
  {
    title: 'Training and Education',
    description: 'Training and Education programs offer opportunities for learning and professional development in various areas related to mental health and wellness. This includes workshops, seminars, and educational resources aimed at enhancing knowledge and skills.',
  },
  {
    title: 'Legal & Financial Consultation',
    description: 'Legal & Financial Consultation provides guidance and support on legal and financial matters that may impact an individual’s mental health and well-being. This includes advice on legal issues, financial planning, and accessing resources for assistance.',
  },
  {
    title: 'Crisis Intervention Services',
    description: 'Crisis Intervention Services offer immediate support and assistance during emergency situations. This includes providing counseling, safety planning, and other interventions to address urgent needs and stabilize individuals in crisis.',
  },
];

const ServiceDetail: React.FC = () => {
  const { serviceName } = useParams<{ serviceName: string }>();
  const navigate = useNavigate();

  const service = services.find(
    (service) => service.title.replace(/\s+/g, '-').toLowerCase() === serviceName
  );

  useEffect(() => {
    // Scroll to the top of the page when this component mounts
    window.scrollTo(0, 0);
  }, [serviceName]);

  if (!service) {
    return <div>Service not found</div>;
  }

  return (
    <section id="service-detail" className="py-20 min-h-screen">
      <div className="container mx-auto px-8 lg:px-1 pt-10">
        <div className="mb-4">
          <button
            onClick={() => navigate(-1)} // Go back to the previous page
            className="flex items-center text-customTextColor hover:text-customHoverColor font-bold py-2 px-4"
          >
            <FaArrowLeft className="mr-2" /> Back to Previous Page
          </button>
        </div>
        <div className="service-content p-8 rounded-lg text-customTextColor bg-paddingBackground shadow-lg">
          <div className="text-center double-space">
            <h2 className="text-4xl font-bold mb-10">{service.title}</h2>
            <div
              className="text-lg text-gray-600 max-w-6xl mx-auto"
              dangerouslySetInnerHTML={{ __html: service.description }}
            />
          </div>
        </div>
        {/* Contact Us Section */}
        <div className="text-center mt-10">
          <p className="text-lg text-gray-700 mb-4">
            Need to contact us?{' '}
            <a
              href="tel:215-792-6040" // Updated href for phone link
              className="text-blue-500 hover:text-blue-700 font-semibold"
            >
              Call us at (215)-PWC-6040
            </a>{' '}
            or fill out a{' '}
            <Link to="/contact#contact-section" className="text-blue-500 hover:text-blue-700 font-semibold">
              contact form
            </Link>{' '}
            to get in touch with us.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ServiceDetail;