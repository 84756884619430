import React, { useEffect } from 'react';

const Careers: React.FC = () => {

  
  useEffect(() => {
    if (window.BambooHR && typeof window.BambooHR.init === 'function') {
      window.BambooHR.init();
    }
  }, []);

  return (
    <section id="careers-section" className="py-20 text-center min-h-screen overflow-y-auto">
      <div className="container mx-auto px-8 lg:px-32 p-10">
      <div className="my-12 mx-auto h-1 w-80 flex justify-center items-center bg-customTextColor rounded-md"></div>
        <div className="career-content p-8 rounded-lg text-customTextColor bg-paddingBackground shadow-lg">
          <h1 className="text-4xl font-bold mb-4">Opportunities</h1>
          <div className="gap-8 pt-10">
            <div
              id="BambooHR"
              data-domain="paragonwellness.bamboohr.com"
              data-version="1.0.0"
              data-departmentId=""
              style={{ color: 'var(--tw-text-customTextColor)' }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Careers;
