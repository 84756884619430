import React, { useEffect, useRef } from 'react';
import BackToTopButton from './BackToTopButton';

interface BlogPost {
  id: string;
  title: string;
  category: string;
  link: string;
  imageUrl: string;
}

const Blog: React.FC<{ blogData: BlogPost[] }> = ({ blogData }) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    const cards = document.querySelectorAll('.blog-card');
    cards.forEach((card) => {
      if (observerRef.current) observerRef.current.observe(card);
    });

    return () => {
      if (observerRef.current) {
        cards.forEach((card) => {
          if (observerRef.current) observerRef.current.unobserve(card);
        });
      }
    };
  }, []);

  return (
    <section id="blog-section" className="py-20 text-center min-h-screen overflow-y-auto bg-beige">
      <div className="container mx-auto px-8 lg:px-1 pt-10">
        <div className="blog-content p-8 rounded-lg text-customTextColor bg-white shadow-lg">
          <h2 className="text-4xl font-bold mb-20 text-customTextColor">Tips For a Better Tomorrow</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {blogData.map((post) => (
              <div
                key={post.id}
                className="blog-card rounded-lg overflow-hidden shadow-lg relative bg-white"
                style={{
                  backgroundImage: `url(${post.imageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '300px',
                }}
              >
                <a
                  href={post.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline"
                >
                  <div
                    className="absolute bottom-0 left-0 w-full bg-backgroundOverlay bg-opacity-50 p-4"
                    style={{
                      backdropFilter: 'blur(4px)', // Optional: Adds a blur effect
                      textShadow: '0 2px 7px rgba(0, 0, 0, 0.4)', // Optional: Adds a shadow to the text
                    }}
                  >
                    <h3 className="text-lg font-bold">{post.title}</h3>
                    <p className="text-sm">{post.category}</p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
        <BackToTopButton />
      </div>
    </section>
  );
};

export default Blog;
