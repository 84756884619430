import React from 'react';
import './Member.css';

const Member: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between w-full h-screen bg-beige">
      {/* Content */}
      <div className="flex-1 flex flex-col items-center justify-center p-6 md:p-16">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-customTextColor mb-6 text-center">
          Welcome to the Member Portal
        </h1>
        <div className="bg-white text-gray-800 p-8 rounded-xl shadow-2xl border border-gray-300 mb-6">
          <p className="mb-6 text-lg">
            To access the member area, click the button below. You will be redirected to our secure login page.
          </p>
          
          <a 
            href="https://mylifeexpert.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="button-wrapper cta-button1 text-white font-semibold py-3 px-6 w-44 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Member Login
          </a>
        </div>
      </div>

      {/* Side Video */}
      <div className="w-full md:w-3/6 p-16 md:pl-12 flex flex-col items-center">
        <div className="relative w-full aspect-w-16 aspect-video bg-black rounded-lg overflow-hidden border border-gray-700 shadow-xl">
          <video
            className="absolute top-0 left-0 w-full h-full object-contain"
            muted
            controls
            poster="service-images/thumbnail.jpg" // Update this path to your thumbnail image
          >
            <source src="logos/member-login-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Member;
