import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import About from './About';
import Home from './Home';
import Services from './Services';
import Contact from './Contact';
import Careers from './Careers';
import Member from './Member';
import Eap from './Eap';
import EAPDetail from './EAPDetail';
import EmployeeDetail from './EmployeeDetail';
import ServiceDetail from './ServiceDetail';
import Blog from './Blog';
import LotusFlower from './LotusFlower';
import './App.css'; // custom styles

// Extend the Window interface to include BambooHR
declare global {
  interface Window {
    BambooHR: {
      init: () => void;
    };
  }
}

// Define the blogData array
const blogData = [
  {
    id: '1',
    title: 'Understanding Mental Health',
    category: 'Mental Health',
    link: 'https://www.uchicagomedicine.org/forefront/health-and-wellness-articles/2022/may/mental-health-awareness-q-and-a',
    imageUrl: 'https://api.microlink.io/?url=https://www.uchicagomedicine.org/forefront/health-and-wellness-articles/2022/may/mental-health-awareness-q-and-a&embed=image.url',
  },
  {
    id: '2',
    title: 'Tips for a Healthy Work-Life Balance',
    category: 'Work-Life Balance',
    link: 'https://www.betterup.com/blog/how-to-have-good-work-life-balance',
    imageUrl: 'https://api.microlink.io/?url=https://www.betterup.com/blog/how-to-have-good-work-life-balance&embed=image.url',
  },
  {
    id: '3',
    title: 'Understanding Addiction',
    category: 'Understand Addiction',
    link: 'https://www.yalemedicine.org/news/how-an-addicted-brain-works',
    imageUrl: 'https://api.microlink.io/?url=https://www.yalemedicine.org/news/how-an-addicted-brain-works&embed=image.url',
  },
  {
    id: '4',
    title: 'What Is Your Family Medical History?',
    category: 'Why Your Family Health History Matters',
    link: 'https://www.pfizer.com/news/articles/why_your_family_health_history_matters',
    imageUrl: 'https://api.microlink.io/?url=https://cdn.pfizer.com/pfizercom/styles/convert_to_webp/s3/2020-11/banner_section/group_laugh_1920X600.jpg.webp?VersionId=rkGcfA2eq.Q1yWfpY9K29LNO1lMb6F79&itok=_w_zQJpV&embed=image.url',
  },
  {
    id: '5',
    title: 'A Parents Role',
    category: 'Parenting',
    link: 'https://www.psychologytoday.com/us/basics/parenting',
    imageUrl: 'https://api.microlink.io/?url=https://cdn2.psychologytoday.com/assets/styles/max_800/public/center/2021-01/shutterstock_1142016005%281%29.jpg?itok=OvsjoQOl&embed=image.url',
  },
  {
    id: '6',
    title: 'Greater Philadelphia Resource Guide',
    category: 'Community Resources',
    link: 'https://critpath.org/resource-guide/',
    imageUrl: 'https://api.microlink.io/?url=https://www.phila.gov/media/20230201101327/CRC-program-image-2023.jpg&embed=image.url',
  },
  // Add more blog posts as needed
];


const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // Load the BambooHR script as soon as the app loads
    const loadBambooHREmbedScript = () => {
      if (!document.querySelector(`script[src="https://paragonwellness.bamboohr.com/js/embed.js"]`)) {
        const script = document.createElement('script');
        script.src = "https://paragonwellness.bamboohr.com/js/embed.js";
        script.type = "text/javascript";
        document.body.appendChild(script);

        script.onload = () => {
          // Optionally, initialize BambooHR after loading
          if (window.BambooHR && window.BambooHR.init) {
            window.BambooHR.init();
          }
        };
      }
    };

    loadBambooHREmbedScript();

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2300); // Adjust the timing as necessary

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="relative min-h-screen flex flex-col">
        {isLoading && <LotusFlower />}

        {/* Background */}
        <div className="fixed inset-0 bg-cover bg-center"></div>
        
        {/* Content */}
        <div id="main-content" className={`relative z-10 flex-1 ${isLoading ? 'hidden' : ''}`}>
          <Navbar />
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/staff/:employeeName" element={<EmployeeDetail />} />
            <Route path="/services/:serviceName" element={<ServiceDetail />} />
            <Route path="/home" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog" element={<Blog blogData={blogData} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/member" element={<Member />} />
            <Route path="/eap" element={<Eap />} />
            <Route path="/eap/:serviceName" element={<EAPDetail />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
