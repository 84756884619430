import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPhoneAlt } from 'react-icons/fa'; // Import the Font Awesome phone icon

interface BannerProps {
  isVisible: boolean;
  bannerRef: React.RefObject<HTMLDivElement>; // Define bannerRef as a prop
}

const Banner: React.FC<BannerProps> = ({ isVisible, bannerRef }) => {
  const bannerContentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleScroll() {
      if (bannerRef.current && bannerContentRef.current) {
        // Adjust banner's behavior based on bannerRef and bannerContentRef if needed
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [bannerRef]);

  const handleNavigation = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  return (
    <div
      ref={bannerRef}
      className={`${isVisible ? 'block' : 'hidden'} bg-fore text-white p-4 pl-10 pr-8 flex justify-between items-center`}
    >
      {/* Logo */}
      <button onClick={() => handleNavigation('/home')} className="flex items-center">
        <img src="/logos/fullLogo.png" alt="logo" className="h-16 w-full inline-block mr-2" />
      </button>

      {/* Opening Soon Text */}
      <div className="text-customTextColor relative right-12 left-1 underline text-3xl font-serif italic"> {/* Customize font style here */}
        Opening Soon
      </div>

      {/* Phone Call Button */}
      <a
        href="tel:215-792-6040"
        className="flex items-center rounded-full bg-white text-customTextColor px-4 py-2 font-bold"
      >
        <FaPhoneAlt className="text-customTextColor h-4 w-4 mr-2" /> {/* Adjusted size here */}
        Call Us: (215)-PWC-6040
      </a>
    </div>
  );
};

export default Banner;
