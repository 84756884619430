import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const staff = [
    { name: 'Kimberly Pakech', position: 'CEO', position2: '(Chief Executive Officer)', summary: '', email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/Unknown_person.jpg' },
    { name: 'Ashley Meehan', position: 'CFO', position2: '(Chief Financial Officer)', summary: '', email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/Unknown_person.jpg' },
    { name: 'Matthew Darabasz', position: 'CTO', position2: '(Chief Technology Officer)', summary: '', email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/matt.jpg' },
    { name: 'Marc Bongiovanni', position: 'CBO', position2: '(Chief Business Officer)', summary: '', email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/Unknown_person.jpg' },
    { name: 'James B. Watson', position: 'DO', position2: '(Chief Medical Director)', summary: '', email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/Unknown_person.jpg' },
    { name: 'Claire Keller', position: 'Office Manager', position2: '', summary: "Claire has been in long-term recovery from substance use for 35 years and is deeply involved in the recovery community. Her personal experience shows that recovery is possible with support and engagement. Claire’s insights are invaluable for those new to treatment and recovery. She emphasizes compassionate care, consistent follow-up, and fostering peer support. As a first point of contact for those seeking help, Claire's dedication to sobriety and uplifting others makes her an invaluable asset. Paragon Wellness is fortunate to have her on our front line", email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/claire_keller.jpg' },
    { name: 'Dawn Pakech', position: 'Clinical Supervisor', position2: '', summary: '', email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/Unknown_person.jpg' },
    { name: 'Elizabeth Higgins', position: 'Certified Peer Specialist', position2: 'CRS', summary: "Elizabeth 'Liz' Higgins is a Certified Recovery Specialist (CRS) with years of experience helping individuals rebuild their lives and achieve wholeness. Here at Paragon Wellness, we are certain that she will serve as a beacon of hope and strength for those experiencing uncertainty, anxiety, or living through grief", email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/elizabeth-higgins.jpg' },
    { name: 'Tina M. Wright', position: 'Therapist', position2: 'MA, CADC Certified', summary: "Tina has been practicing in the mental and behavioral health space for 20 years, dedicating much of her expertise to those living with substance use disorders. In addition, Tina is well-equipped to assist families in crisis and individuals with dual diagnosis concerns. Her gift is creating a safe environment for people to share and heal. We at Paragon Wellness, are honored to have her as part of our team.", email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/tina-wright.jpg' },
    { name: 'Mark Ryan', position: 'Therapist', position2: '', summary: '', email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/mark-ryan.jpg' },
    { name: 'Roxanne Logan', position: 'Therapist', position2: 'JD, MSS, LCSW Certified', summary: "Roxanne Logan is a therapist with law and social work degrees and over 16 years of experience in the Philadelphia Child Welfare System. Her passion for social justice aligns with Paragon Wellness's mission to provide safe, ethical, and judicious care. Roxanne has dedicated her career to enhancing the lives of children and families in crisis through counseling, education, and advocacy. Her skill in working with diverse audiences to improve their quality of life makes her an invaluable member of our clinical team. We are proud to have Roxanne as one of our clinical caregivers.", email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/roxanne.jpg' },
    { name: 'Burnadette Bordoni', position: 'Counseling Intern', position2: '', summary: "At Paragon Wellness, we believe in social and emotional safety for all. Burnadette’s philosophy embodies this through her principled and empathetic approach in practice, guiding individuals on their journey to safety and self-determination. Inspired by Bryan Stevenson's quote, 'Each of us is more than the worst thing we've ever done,' Burnadette is dedicated to honoring the 'more' in everyone. We are grateful to have her on our team.", email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/burnadette.jpg' },
    { name: 'Allison Lagera', position: 'Administrative Assistant', position2: '', summary: '', email: 'random.random@paragonwellness.org', imageUrl: '/staff-images/Unknown_person.jpg' },
];

const EmployeeDetail: React.FC = () => {
  const { employeeName } = useParams<{ employeeName: string }>();
  const navigate = useNavigate();

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  const employee = staff.find(member => member.name.replace(/\s+/g, '-').toLowerCase() === employeeName);

  if (!employee) {
    return <div>Employee not found</div>;
  }

  return (
    <section id="employee-detail" className="py-20 min-h-screen">
      <div className="container mx-auto px-8 lg:px-1 pt-10">
        <div className="mb-4">
          <button
            onClick={() => navigate(-1)} // Go back to the previous page
            className="flex items-center text-customTextColor hover:text-customHoverColor font-bold py-2 px-4"
          >
            <FaArrowLeft className="mr-2" /> Back to Previous Page
          </button>
        </div>
        <div className="employee-content p-8 rounded-lg text-customTextColor bg-paddingBackground shadow-lg">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Left Column: Image and Position Details */}
            <div className="md:col-span-1 flex flex-col items-center md:items-start text-center md:text-left">
              <img
                src={employee.imageUrl}
                alt={employee.name}
                className="w-48 h-48 mb-4 object-cover rounded-lg"
                style={{ aspectRatio: '1/1' }} // Ensure it's a square
              />
              <h2 className="text-2xl font-bold mb-2">{employee.name}</h2>
              <p className="text-md text-gray-600">{employee.position} {employee.position2}</p>
              <p className="text-md text-gray-600 mt-2">Email: {employee.email}</p>
            </div>
            {/* Right Column: Bio */}
            <div className="md:col-span-2 mt-12 px-5">
              <p className="text-md text-gray-600 indent-2">{employee.summary}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EmployeeDetail;