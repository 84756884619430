import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BackToTopButton from './BackToTopButton';

const Contact: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState<null | boolean>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const { hash } = useLocation(); // Get the hash from the URL

  // Scroll to the section with the corresponding hash when the component loads
  useEffect(() => {
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        const offset = 50; // Adjust the offset value to control how far up it scrolls
        const elementPosition = section.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [hash]);

  const getLocationLink = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const googleMapsLink = "https://maps.google.com/?q=3396+Miller+Street,+Philadelphia,+PA+19134";
    const appleMapsLink = "http://maps.apple.com/?q=3396+Miller+Street,+Philadelphia,+PA+19134";

    return isIOS ? appleMapsLink : googleMapsLink;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSubmitSuccess(null);
    setErrorMessage('');

    const nameRegex = /^[a-zA-Z]+$/;
    if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
      setErrorMessage('Please enter a valid first and last name.');
      setIsSubmitting(false);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      setIsSubmitting(false);
      return;
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      setErrorMessage('Please enter a valid phone number (10 digits).');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          phone,
          description,
        }),
      });

      if (!response.ok) {
        const result = await response.json();
        setErrorMessage(result.error || 'An error occurred.');
        setSubmitSuccess(false);
      } else {
        setSubmitSuccess(true);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred.';
      setErrorMessage('An error occurred: ' + errorMessage);
      setSubmitSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact-page "className="bg-beige">
      {/* Location Section */}
      <section id="location-section" className="container mx-auto px-8 lg:px-1 p-10 py-32">
        <div className="location-content p-8 rounded-lg text-customTextColor bg-white shadow-lg">
          <h1 className="text-4xl font-bold mb-4 flex flex-col justify-center items-center mt-6">Location</h1>
          <div className="mt-10 flex flex-col justify-center items-center">
            <h1 className="text-2xl font-semibold mb-10">Philadelphia, PA</h1>
            {/* Google Maps Embed */}
            <iframe
              width="95%"
              height="600"
              frameBorder="0"
              style={{ border: 0, borderRadius: '10px' }}
              src="https://www.google.com/maps/embed/v1/place?q=3396+Miller+Street,+Philadelphia,+PA+19134&key=AIzaSyACNUPi5gJox5tj7jip4a-Vyu1ZXRcTv5o"
              allowFullScreen
              aria-hidden="false"
              tabIndex={0}
              title="Location Map"
            ></iframe>
            <div className="my-5 text-lg text-customTextColor font-semibold underline hover:text-customHoverColor">
              <a
                href={getLocationLink()}  // Use the geolocation link
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"  // Use flex to align image and text
              >
                <img
                  src="/locations/locpin.png"
                  alt="Location Pin"
                  className="w-3 h-4 mr-2"  // Set image size and add margin to the right
                />
                3396 Miller Street, Philadelphia, PA 19134
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section id="contact-section" className="container mx-auto px-8 lg:px-1 pb-24">
        <div className="contact-content p-8 rounded-lg text-customTextColor bg-white shadow-lg">
          <h1 className="text-4xl font-bold mb-4 flex flex-col justify-center items-center mt-6">Contact Us</h1>
          {submitSuccess === true && (
            <p className="text-green-500 font-bold flex flex-col justify-center items-center">Form submitted successfully!</p>
          )}
          {submitSuccess === false && (
            <p className="text-red-500 font-bold flex flex-col justify-center items-center">{errorMessage}</p>
          )}
          <div className="mt-20 flex justify-center items-center">
            <form className="contact-form grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-5xl" onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="block text-left mb-2 text-lg font-semibold" htmlFor="firstName">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-customTextColor bg-formInput"
                  placeholder="Enter your first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label className="block text-left mb-2 text-lg font-semibold" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-customTextColor bg-formInput"
                  placeholder="Enter your last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-span-1 md:col-span-2">
                <label className="block text-left mb-2 text-lg font-semibold" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-customTextColor bg-formInput"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-span-1 md:col-span-2">
                <label className="block text-left mb-2 text-lg font-semibold" htmlFor="phone">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-customTextColor bg-formInput"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-span-1 md:col-span-2">
                <label className="block text-left mb-2 text-lg font-semibold" htmlFor="description">
                  Reason for Contact (Optional)
                </label>
                <textarea
                  id="description"
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-customTextColor bg-formInput"
                  placeholder="Tell us how we can help you"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="form-group col-span-1 md:col-span-2 text-right">
                <button
                  type="submit"
                  className="px-6 py-3 bg-customTextColor text-white font-bold rounded-lg shadow-lg hover:bg-opacity-90 transition-opacity duration-300"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
        <BackToTopButton />
      </section>
    </section>
  );
};

export default Contact;
