import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BackToTopButton from './BackToTopButton';

const Services: React.FC = () => {
  const { hash } = useLocation();
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        const offset = 100; // Adjust this value to scroll higher
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [hash]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    const cards = document.querySelectorAll('.service-card');
    cards.forEach((card) => {
      if (observerRef.current) observerRef.current.observe(card);
    });

    return () => {
      if (observerRef.current) {
        cards.forEach((card) => {
          if (observerRef.current) observerRef.current.unobserve(card);
        });
      }
    };
  }, []);

  const settings = (serviceCount: number) => ({
    dots: true,
    infinite: false, // Infinite only if more than one card
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const services = [
    {
      category: 'Clinical Services',
      id: 'clinical',
      services: [
        {
          title: 'Individual Therapy',
          description: '',
          imageUrl: '/service-images/individual-therapy.webp',
        },
        {
          title: 'Group Therapy',
          description: '',
          imageUrl: '/service-images/group-therapy.webp',
        },
        {
          title: 'Psychodrama',
          description: '',
          imageUrl: '/service-images/psychodrama.jpg',
        },
        {
          title: 'Clinical Evaluation & Diagnosis',
          description: '',
          imageUrl: '/service-images/evaluation-diagnosis.webp',
        },
        {
          title: 'Clinical Treatment',
          description: '',
          imageUrl: '/service-images/clinical-treatment.jpg',
        },
        {
          title: 'Trauma Treatment',
          description: '',
          imageUrl: '/service-images/trauma-treatment.webp',
        },
      ],
    },
    {
      category: 'Medical Services',
      id: 'medical',
      services: [
        {
          title: 'Medical Evaluation & Recommendation',
          description: '',
          imageUrl: '/service-images/medical-recommendation.webp',
        },
        {
          title: 'Medical Care',
          description: '',
          imageUrl: '/service-images/medical-care.webp',
        },
        {
          title: 'Medical Case Management',
          description: '',
          imageUrl: '/service-images/medical-case-management.webp',
        },
        {
          title: 'Referral Services',
          description: '',
          imageUrl: '/service-images/referral-services.webp',
        },
      ],
    },
    {
      category: ' Clinical Case Management',
      id: 'case-management',
      services: [
        {
          title: 'Supportive Services',
          description: '',
          imageUrl: '/service-images/supportive-services.webp',
        },
        {
          title: 'Resource Coordination',
          description: '',
          imageUrl: '/service-images/resource-coordination.jpg',
        },
        {
          title: 'Professional Collaboration',
          description: '',
          imageUrl: '/service-images/professional-collaboration.webp',
        },
        {
          title: 'After Care Planning',
          description: '',
          imageUrl: '/service-images/after-care-planning.webp',
        },
        {
          title: 'Follow up Case Management',
          description: '',
          imageUrl: '/service-images/follow-up-case-management.webp',
        },
      ],
    },
  ];
  

  return (
    <section id="services-section" className="py-20 text-center min-h-screen overflow-y-auto bg-beige">
      <div className="container mx-auto px-8 lg:px-1 pt-10">
        <div className="services-content p-8 rounded-lg text-customTextColor bg-white shadow-lg">
          {services.map((serviceCategory, idx) => (
            <div key={idx} id={serviceCategory.id} className="mb-24">
              {idx > 0 && (
                <div className="mb-5 mt-10 mx-auto h-1 w-52 bg-oliveGreen rounded-md"></div>
              )}
              <h2 className="text-3xl font-bold mb-5">{serviceCategory.category}</h2>
              <Slider {...settings(serviceCategory.services.length)}>
                {serviceCategory.services.map((service, index) => (
                  <div key={index} className="p-6">
                    <Link to={`/services/${service.title.replace(/\s+/g, '-').toLowerCase()}`}>
                      <div
                        className="service-card visible rounded-lg flex flex-col justify-end text-center shadow-lg bg-white relative"
                        style={{
                          backgroundImage: `url(${service.imageUrl})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          height: '300px',
                        }}
                      >
                        {/* Background overlay for the bottom */}
                        <div
                          className="absolute bottom-0 left-0 w-full bg-backgroundOverlay bg-opacity-50 py-7 shadow-lg rounded-b-md"
                          style={{
                            backdropFilter: 'blur(4px)', // Optional: Adds a blur effect
                            textShadow: '0 2px 7px rgba(0, 0, 0, 0.4)', // Optional: Adds a shadow to the text
                          }}
                        >
                          <h3 className="text-xl font-bold">{service.title}</h3>
                          <p className="text-md">{service.description}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          ))}
        </div>
      </div>
      <BackToTopButton />
    </section>
  );
};

export default Services;
