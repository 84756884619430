// src/components/LotusFlower.tsx
import React from 'react';
import './LotusFlower.css';

const LotusFlower: React.FC = () => {
  return (
    <div id="loading-screen" className="fixed inset-0 flex items-center justify-center bg-white z-50">
      <div className="lotus-flower-animation"></div>
    </div>
  );
};

export default LotusFlower;
