import React from 'react';
import { Link } from 'react-router-dom';
import BackToTopButton from './BackToTopButton';

const EAP: React.FC = () => {
  const eapServices = [
    {
      title: 'Mental & Emotional Health Support',
      description: 'Confidential counseling for employees dealing with personal issues.',
      imgSrc: 'service-images/mental-health-support.webp',
      link: '/eap/mental-&-emotional-health-support',
    },
    {
      title: 'Substance Use Disorder Treatment',
      description: 'Workshops and resources to help manage stress effectively.',
      imgSrc: 'service-images/substance-use-treatment.webp',
      link: '/eap/substance-use-disorder-treatment',
    },
    {
      title: 'Work-Life Fulfillment Services',
      description: 'Get guidance on managing your finances, debt, and investments.',
      imgSrc: 'service-images/work-life-balance.webp',
      link: '/eap/work-life-fulfillment-services',
    },
    {
      title: 'Health & Wellness Program',
      description: 'Get guidance on managing your finances, debt, and investments.',
      imgSrc: 'service-images/health-wellness.webp',
      link: '/eap/health-&-wellness-program',
    },
    {
      title: 'Family Support Services',
      description: 'Get guidance on managing your finances, debt, and investments.',
      imgSrc: 'service-images/family-support.webp',
      link: '/eap/family-support-services',
    },
    {
      title: 'Training & Education',
      description: 'Get guidance on managing your finances, debt, and investments.',
      imgSrc: 'service-images/training-education.webp',
      link: '/eap/training-&-education',
    },
    {
      title: 'Critical Incident Stress Management',
      description: 'Critical Incident Stress Management Support is Available Upon Request',
      imgSrc: 'service-images/critical-incident-stress-management.webp',
      link: '/eap/critical-incident-stress-management',
    },
  ];

  return (
    <section id="eap-section" className="py-20 text-center min-h-screen overflow-y-auto bg-beige">
      <div className="container mx-auto px-8 lg:px-1 pt-10">
        <div className="eap-content p-8 rounded-lg text-customTextColor bg-white shadow-lg">
          <h1 className="text-4xl font-bold mb-20 text-customTextColor">Employee Assistance Program (EAP)</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {eapServices.map((service, index) => (
              <Link to={service.link} key={index} className="group">
                <div
                  className="eap-card visible rounded-lg flex flex-col justify-end text-center shadow-lg bg-white relative"
                  style={{
                    backgroundImage: `url(${service.imgSrc})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '300px', // Adjust to match the size of the Services cards
                  }}
                >
                  {/* Background overlay for the bottom */}
                  <div
                    className="absolute bottom-0 left-0 w-full bg-backgroundOverlay bg-opacity-50 py-4 px-6 shadow-lg rounded-b-lg"
                    style={{
                      backdropFilter: 'blur(4px)', // Optional: Adds a blur effect
                      textShadow: '0 2px 7px rgba(0, 0, 0, 0.4)', // Optional: Adds a shadow to the text
                    }}
                  >
                    <h3 className="text-xl font-bold">{service.title}</h3>
                    <p className="text-md">{service.description}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <BackToTopButton />
    </section>
  );
};

export default EAP;
